@import url('https://fonts.googleapis.com/css2?family=Anton&family=Finger+Paint&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leftShadowTable {
  box-shadow: -9px 0px 12px -1px rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: -9px 0px 12px -1px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: -9px 0px 12px -1px rgba(0, 0, 0, 0.07);
}

.rightShadowTable {
  box-shadow: 12px 0px 12px -1px rgba(0, 0, 0, 0.07) !important;
  -webkit-box-shadow: 12px 0px 12px -1px rgba(0, 0, 0, 0.07) !important;
  -moz-box-shadow: 12px 0px 12px -1px rgba(0, 0, 0, 0.07) !important;
}

.removeShadowTable {
  transition: linear .3s;
  box-shadow: none;
}

.fontAnton {
  font-family: 'Anton', sans-serif!important;
}

.fontFinger {
  font-family: 'Finger Paint', sans-serif!important;
}